<template>
    <page-title
        title="大和郡山市立北部小学校 行事一覧"
        icon="bi-calendar-event"
    >
        <router-link :to="{name: 'SchoolList'}" class="btn btn-outline-primary">
            <i class="bi bi-reply"></i> 学校一覧へ
        </router-link>
    </page-title>

    <section class="section">
        <table class="table  table-striped mb-4">
            <thead>
                <tr class="table-primary">
                    <th class="text-center">行事名</th>
                    <th class="text-center">標準実施日</th>
                    <th class="text-center">販売</th>
                    <th class="text-center">集合写真</th>
                    <th class="text-center">学年</th>
                    <th class=""></th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="event in events" :key="event">
                    <td class="text-center">
                        {{ event.name }}
                    </td>
                    <td class="text-center">
                        {{ event.date }}
                    </td>
                    <td class="text-center">
                        {{ event.sales_type }}
                    </td>
                    <td class="text-center">
                        {{ event.photo }}
                    </td>
                    <td class="text-center">
                        {{ event.grade }}
                    </td>
                    <td class="text-center">
                        <button type="button" class="btn btn-primary" @click="openModal">
                            <i class="bi bi-pencil-square"></i> 編集
                        </button>
                    </td>
                </tr>
            </tbody>
            <tfoot></tfoot>
        </table>
        <div class="ms-2">
            <button class="btn btn-primary" @click="openAddModal"><i class="bi bi-plus-lg"></i> 行事を追加</button>
        </div>
    </section>

    <!-- モーダル -->
    <div v-if="modal">
        <transition name="fade">
            <div v-if="modal">
                <div class="modal-backdrop show"></div>
                <div class="modal" tabindex="-1" style="display:block">
                    <div class="modal-dialog" style="max-width: 70% !important">
                        <div class="modal-content">
                            <div class="modal-header">
                                <h4 class="modal-title">
                                    大和郡山市立北部小学校 行事編集
                                </h4>
                                <button type="button" class="btn-close" @click="closeModal"></button>
                            </div>
                            <div class="modal-body">
                                <div class="row mb-5">
                                    <div class="form-group col-md-6">
                                        <label>行事名</label>
                                        <input type="text" class="form-control" required value="入学式">
                                    </div>
                                    <div class="form-group col-md-6">
                                        <label>標準実施日</label>
                                        <div class="input-group">
                                            <select class="form-select">
                                                <template v-for="month in months" :key="month">
                                                    <template v-if="month === 4" selected>{{ month }}</template>
                                                    <option>{{ month }}</option>
                                                </template>
                                            </select>
                                            <span class="input-group-text">月</span>
                                            <select class="form-select">
                                                <template v-for="day in days" :key="day">
                                                    <template v-if="month === 2" selected>{{ month }}</template>
                                                    <option>{{ day }}</option>
                                                </template>
                                            </select>
                                            <span class="input-group-text">日</span>
                                        </div>
                                    </div>
                                    <div class="form-group col-md-6">
                                        <label>学年</label>
                                        <select class="form-select">
                                            <option value="1" selected>1</option>
                                            <option value="2">2</option>
                                            <option value="3">3</option>
                                            <option value="4">4</option>
                                            <option value="5">5</option>
                                            <option value="6">6</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="row mb-5">
                                    <div class="form-group col-md-6">
                                        <label>販売</label>
                                        <select class="form-select">
                                            <option>あり</option>
                                            <option selected>なし</option>
                                        </select>
                                    </div>
                                    <div class="form-group col-md-6">
                                        <label>集合写真</label>
                                        <select class="form-select">
                                            <option>自由販売</option>
                                            <option>買取販売</option>
                                            <option selected>なし</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div class="modal-footer d-flex justify-content-between">
                                <button type="button" class="btn btn-primary" @click="closeModal">
                                    <i class="bi bi-pencil-square"></i> 保存
                                </button>
                                <button type="button" class="btn btn-outline-danger" @click="$refs.confirm_remove.show()">
                                    <i class="bi bi-trash"></i> 削除...
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </transition>
    </div>

    <!-- モーダル -->
    <div v-if="add_modal">
        <transition name="fade">
            <div v-if="add_modal">
                <div class="modal-backdrop show"></div>
                <div class="modal" tabindex="-1" style="display:block">
                    <div class="modal-dialog" style="max-width: 70% !important">
                        <div class="modal-content">
                            <div class="modal-header">
                                <h4 class="modal-title">
                                    大和郡山市立北部小学校 行事登録
                                </h4>
                                <button type="button" class="btn-close" @click="closeAddModal"></button>
                            </div>
                            <div class="modal-body">
                                <div class="row mb-5">
                                    <div class="form-group col-md-6">
                                        <label>行事名</label>
                                        <input type="text" class="form-control" required>
                                    </div>
                                    <div class="form-group col-md-6">
                                        <label>標準実施日</label>
                                        <div class="input-group">
                                            <select class="form-select">
                                                <template v-for="month in months" :key="month">
                                                    <option>{{ month }}</option>
                                                </template>
                                            </select>
                                            <span class="input-group-text">月</span>
                                            <select class="form-select">
                                                <template v-for="day in days" :key="day">
                                                    <option>{{ day }}</option>
                                                </template>
                                            </select>
                                            <span class="input-group-text">日</span>
                                        </div>
                                    </div>
                                    <div class="form-group col-md-6">
                                        <label>学年</label>
                                        <select class="form-select">
                                            <option>1</option>
                                            <option>2</option>
                                            <option>3</option>
                                            <option>4</option>
                                            <option>5</option>
                                            <option>6</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="row mb-5">
                                    <div class="form-group col-md-6">
                                        <label>販売</label>
                                        <select class="form-select">
                                            <option>あり</option>
                                            <option>なし</option>
                                        </select>
                                    </div>
                                    <div class="form-group col-md-6">
                                        <label>集合写真</label>
                                        <select class="form-select">
                                            <option>自由販売</option>
                                            <option>買取販売</option>
                                            <option>なし</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div class="modal-footer d-flex justify-content-between">
                                <button type="button" class="btn btn-primary" @click="addEvent">
                                    <i class="bi bi-pencil-square"></i> 保存
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </transition>
    </div>

    <confirm-dialog ref="confirm_remove" @ok="closeModal()">
        <p>削除してよろしいですか？</p>
    </confirm-dialog>

</template>

<script>
import PageTitle from '@/components/PageTitle';
import ConfirmDialog from '@/components/tools/ConfirmDialog';


export default {
    name: 'PartnerList',
    components: {
        PageTitle,
        ConfirmDialog,
    },
    data() {
        return {
            events: [
                { name: '入学式', date: '4/7', sales_type: 'なし', photo: 'なし', grade: 1 },
                { name: '修学旅行', date: '5/10', sales_type: 'あり', photo: '買取販売', grade: 6},
                { name: '5年生遠足', date: '5/20', sales_type: 'あり', photo: '自由販売', grade: 5},
                { name: '林間学校', date: '8/24', sales_type: 'あり', photo: '自由販売', grade: 5},
                { name: '運動会', date: '10/1', sales_type: 'あり', photo: 'なし', grade: 6},
                { name: '卒業式', date: '3/24', sales_type: 'なし', photo: 'なし', grade: 6},
            ],
            months: [1,2,3,4,5,6,7,8,9,10,11,12],
            days: [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31],
            modal: false,
            add_modal: false,
            modal_title: '',
        }
    },

    methods: {
        addEvent() {
            this.events.push({ name: '入学式', date: '4/7', sales_type: 'なし', photo: 'なし', grade: 1});
            this.add_modal = false;
        },
        openAddModal() {
            this.add_modal = true;

        },
        closeAddModal() {
            this.add_modal = false;
        },
        openModal() {
            this.modal = true;

        },
        closeModal() {
            this.modal = false;
        }
    }
}
</script>

<style scoped>

</style>
